import React, { Component } from 'react'
import "../../DKUILibrary/DKUILibrary.css"
// import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
// import PayslipFieldUtility from '../../Templates/PayslipTemplates/PayslipFieldUtility';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import { getVW } from '../../Utilities/Utility';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    TemplateSettingsManager,
    getLocalisedText,
    getVW,
    PayslipFieldUtility,
    Utility
} from 'deskera-doc-builder-lib';

export default class PayslipFieldConfigurationPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data.payslip,
            payslipFields: [],
            selectedIndex: undefined,
            payslipFieldOptions: TemplateSettingsManager.defaultTemplateSettings.payslipFieldOptions || {}
        }
    }

    componentDidMount() {
        this.updatePayslipFieldOptions();
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 450,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={this.getTitle()}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onClosePressed}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    id='address_table_popup'
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        width: '100%',
                        height: '650px',
                        alignItems: 'center',
                        overflow: "hidden scroll"
                    }}
                >
                    {this.getFieldOptions()}
                    {this.getOptions()}
                </div>
            </div>
        )
    }

    getTitle() {
        return getLocalisedText('field_configuration')
    }

    getFieldOptions() {
        return (
            <div style={{width: '100%', paddingBottom: 10, paddingTop: 5}}>
                <div className='fontStyleBold pl-r pr-r user-select-none' style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 14,
                }}>
                    {getLocalisedText('field_options')}
                </div>
                {this.getCheckboxConfiguration("show_custom_fields", "showCustomFields")}
            </div>
        )
    }

    getCheckboxConfiguration(message, key) {
        return <div className='RowDiv'
            style={{
                width: '100%',
                paddingLeft: 13,
                paddingRight: 13,
                boxSizing: 'border-box',
                position: 'relative'
            }}
        >
            <input
                type="checkbox"
                checked={this.state.payslipFieldOptions[key]}
                id={`field_option_checkbox_${key}`}
                onClick={() => {
                    this.onFieldOptionToggle(key);
                }}
                disabled={false}
            />
            <div
                className='TextField ListPicker'
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                    pointerEvents: 'none'
                }}>
                {getLocalisedText(message)}
            </div>
        </div>
    }

    updatePayslipFieldOptions() {
        const fields = PayslipFieldUtility.getFields(this.state.data.payslip, this.state.payslipFieldOptions);
        this.setState({
            payslipFields: fields
        });
    }

    onFieldOptionToggle(key) {
        this.setState({
            payslipFieldOptions: {
                ...this.state.payslipFieldOptions,
                [key]: !this.state.payslipFieldOptions[key]
            }
        }, this.updatePayslipFieldOptions);
    };

    getOptions() {
        var sortedList = this.state.payslipFields.sort((a, b) => a.index - b.index)
        return sortedList.map((item) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                isTextEditable
            />
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if (startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if (dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if (this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if (startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if (this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var payslipFields = this.state.payslipFields
        payslipFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = payslipFields.filter(x => x.index === startIndex)
        if (selectedItem.length > 0) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            payslipFields: sortedList,
            selectedIndex: undefined,
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
        })
    }

    onUpdateLabel(item, name) {
        var payslipFields = this.state.payslipFields

        payslipFields.forEach(element => {
            if (element.code === item.code) {
                element.label = name
            }
        });

        this.setState({
            payslipFields: payslipFields,
        })
    }

    onSelectClick(item) {
        var payslipFields = this.state.payslipFields

        payslipFields.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            payslipFields: payslipFields,
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var payslipFields = this.state.payslipFields
        payslipFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= payslipFields.length) {
                newIndex = payslipFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = payslipFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            payslipFields: payslipFields,
        })
    }

    onSave() {
        TemplateSettingsManager.updatePayslipFieldOptions(this.state.payslipFieldOptions);
        var payslipTable = this.state.payslipFields
        if (payslipTable.length > 0) {
            var newArray = []
            payslipTable.forEach((element) => {
                var newRowItem = {}
                newRowItem.isSelected = element.isSelected
                newRowItem.type = element.code
                newRowItem.index = element.index
                newRowItem.name = element.label
                newRowItem.isCustomField = element.isCustomField
                newArray.push(newRowItem)
            });
            TemplateSettingsManager.updatePayslipFieldConfiguration(newArray)
        }
        this.props.onClosePressed()
    }
}

