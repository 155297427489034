import { DateFormat } from "../../Constants/Constants"
import ComponentManager from "../../Manager/ComponentManager"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getDateFrom, getDateString, getIsInvoice, getIsQuotation, getIsSalesOrder } from "../../Utilities/Utility"
import AdditionalChargeParser from "../AdditionalChargeParser"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class QuoteAndInvoiceParser {
    static parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        dataFromAPI.tenantInfo = {
            ...dataFromAPI.tenantInfo,
            state: fromAddressObj?.state,
        }
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo
        // billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.billToName, dataFromAPI.billToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var clientShipToAddressObj = new Address(dataFromAPI.shipToName, dataFromAPI.shipToAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        templateDataToReturn.taxRegistrationNumber = ""
        templateDataToReturn.taxPayerId = ""
        if (dataFromAPI.contactInfo && dataFromAPI.contactInfo !== null) {
            templateDataToReturn.taxRegistrationNumber = dataFromAPI.contactInfo.taxNumber ?? ''
            templateDataToReturn.taxPayerId = dataFromAPI.contactInfo.taxPayerIdIsrael ?? ''
        }

        //SA Address
        templateDataToReturn = this.getSaudiArabiaAddress(dataFromAPI, templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat,true)

        templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.dueDate), templateDataToReturn.dateFormat)
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
        templateDataToReturn.ewayBillNoAndDate = ''
        if (dataFromAPI.isEwayGenerated === 'Yes') {
            templateDataToReturn.ewayGenerated = true;
            templateDataToReturn.ewayBillNo = dataFromAPI.ewayBillNo;
            try {
                templateDataToReturn.ewayBillDate = getDateString(getDateFrom(dataFromAPI.ewayBillDate, DateFormat.DD_MM_YYYY_HH_MM_SS_SLASH), templateDataToReturn.dateFormat);
            } catch (error) {}
            templateDataToReturn.ewayBillNoAndDate = templateDataToReturn.ewayBillNo + ' Dt. ' + templateDataToReturn.ewayBillDate
        }
        if (dataFromAPI?.tenantInfo?.gstin && dataFromAPI?.tenantInfo?.country==='IN') {
            templateDataToReturn.placeOfSupply = dataFromAPI.shipToAddress?.placeOfSupply ? dataFromAPI.shipToAddress?.placeOfSupply  : dataFromAPI.shipToAddress.state ;
        }

        //line items
        templateDataToReturn.lineItems = LineItemsParser.getProductLineItem(dataFromAPI, isGeneratedData, templateDataToReturn.dateFormat)

        templateDataToReturn.totalTaxAmount = dataFromAPI.totalTaxAmount ?? 0

        //stock items
        if (isReadOnlyMode) {
            var isContainStockItem = false
            if (templateDataToReturn.stockItems !== undefined && templateDataToReturn.stockItems !== null)  {
                isContainStockItem = templateDataToReturn.stockItems.length > 0
            }
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.stockTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['stockTable'] = { 'isVisible': true }
            }
            TemplateSettingsManager.updateStockTableVisibility(isContainStockItem)
            templateDataToReturn.showStockTable = isContainStockItem
        }
        else {
            templateDataToReturn.showStockTable = true
            TemplateSettingsManager.updateStockTableVisibility(true)
        }

        //payment link
        if (dataFromAPI.paymentLink === null || dataFromAPI.paymentLink === undefined) {
            templateDataToReturn.paymentItems = []
        }
        else if (dataFromAPI.paymentLink === "") {
            templateDataToReturn.paymentItems = []
        } else {
            templateDataToReturn.paymentItems = [dataFromAPI.paymentLink]
        }

        //payment items
        if (isReadOnlyMode) {
            var isContainPaymentItem = templateDataToReturn.paymentItems.length > 0
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }

            TemplateSettingsManager.updatePaymentTableVisibility(isContainPaymentItem)
            templateDataToReturn.showPaymentTable = isContainPaymentItem
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }
            else {
                templateDataToReturn.showPaymentTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable.isVisible
            }
        }

        //additional date
        if (dataFromAPI.shipByDate !== undefined && dataFromAPI.shipByDate !== null) {
            templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.shipByDate), templateDataToReturn.dateFormat)
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
                templateDataToReturn.showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
            }
        }

        templateDataToReturn.deliveryOrderDocNoAndDate = ''
        if (dataFromAPI.deliveryOrders && dataFromAPI.deliveryOrders !== null) {
            let doCodeList = []
            let doDateList = []
            let doCodeAndDateList = []

            if (dataFromAPI.deliveryOrders.length > 0) {
                dataFromAPI.deliveryOrders.forEach(element => {
                    let doCodeAndDate = ''
                    if (element.fulfillmentCode && element.fulfillmentCode !== null) {
                        doCodeList.push(element.fulfillmentCode)
                        doCodeAndDate = element.fulfillmentCode
                    }

                    if (element.documentDate && element.documentDate !== null) {
                        let doDate = getConvertedDate(convertDateFromERPFormatToDocFormat(element.documentDate), templateDataToReturn.dateFormat)
                        if(doDate) {
                            doDateList.push(doDate)
                            doCodeAndDate += ' Dt. ' + doDate
                        }
                    }

                    doCodeAndDateList.push(doCodeAndDate)
                });
            }
            templateDataToReturn.deliveryOrderDocNoAndDate = doCodeAndDateList.join(', ') 
        }

        //add E invoice data
        templateDataToReturn.irn = dataFromAPI.irn
        templateDataToReturn.ackDt = dataFromAPI.ackDt
        templateDataToReturn.ackNo = dataFromAPI.ackNo
        templateDataToReturn.signedQRCode = dataFromAPI.signedQRCode
        //update handling for cancelled e-invoice
        templateDataToReturn.eInvoiceStatus = dataFromAPI.einvoiceStatus ?? ''
        templateDataToReturn.eInvoice = {
            irn: dataFromAPI.irn,
            ackDt: dataFromAPI.ackDt,
            ackNo: dataFromAPI.ackNo,
            signedQRCode: dataFromAPI.signedQRCode
        }
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection === undefined) {
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['eInvoiceSection'] = { 'isVisible': false }
        }
        else {
            templateDataToReturn.showEInvoiceSection = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.eInvoiceSection.isVisible
            if (templateDataToReturn.signedQRCode === undefined || templateDataToReturn.signedQRCode === null || templateDataToReturn.signedQRCode === '') {
                templateDataToReturn.showEInvoiceSection = false
            }
        }

        templateDataToReturn.modeOfTransport = dataFromAPI.modeOfTransport ?? '';

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        //Additional charge and global
        templateDataToReturn = AdditionalChargeParser.getAdditionalCharge(dataFromAPI, templateDataToReturn)

        templateDataToReturn.paymentsFooter = dataFromAPI.payments
        if (templateDataToReturn.showPaymentFooter) {
            templateDataToReturn.payments = templateDataToReturn.paymentsFooter
        }

        if (getIsInvoice(templateDataToReturn.type) || getIsSalesOrder(templateDataToReturn.type)) {
            templateDataToReturn.linkDocumentType = undefined
            templateDataToReturn.linkDocumentNumber = undefined
            if (dataFromAPI.linkedDocument !== undefined && dataFromAPI.linkedDocument !== null) {
                if (dataFromAPI.linkedDocument !== '') {
                    var linkDocumentType = dataFromAPI.linkDocumentType ? dataFromAPI.linkDocumentType.toLowerCase() : ''
                    templateDataToReturn.linkDocumentType = linkDocumentType
                    templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocument
                }
            }
        }

        if (dataFromAPI.theyOweYou !== undefined && dataFromAPI.theyOweYou !== null) {
            if(dataFromAPI.total !== undefined && dataFromAPI.total !== null) {
                templateDataToReturn.outstandingBalance = dataFromAPI.theyOweYou - dataFromAPI.total
            }
            else {
                templateDataToReturn.outstandingBalance = dataFromAPI.theyOweYou - ComponentManager.getTotalOfLineItems(templateDataToReturn.lineItems, false, false, 0)
            }
            templateDataToReturn.outstandingBalanceIncludesCurrent = dataFromAPI.theyOweYou
        }

        templateDataToReturn.linkedDocuments = []
        if (dataFromAPI.linkedDocuments !== undefined && dataFromAPI.linkedDocuments !== null) {
            if (dataFromAPI.linkedDocuments.length > 0) {
                if (dataFromAPI.linkedDocuments.length === 1) {
                    templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                    templateDataToReturn.linkDocumentType = ''
                    templateDataToReturn.linkDocumentNumber = ''
                }
                if (dataFromAPI.linkedDocuments.length === 2) {
                    templateDataToReturn.parentLinkDocumentType = dataFromAPI.linkedDocuments[0].documentType
                    templateDataToReturn.parentLinkDocumentNumber = dataFromAPI.linkedDocuments[0].documentSequenceCode
                    templateDataToReturn.linkDocumentType = dataFromAPI.linkedDocuments[1].documentType
                    templateDataToReturn.linkDocumentNumber = dataFromAPI.linkedDocuments[1].documentSequenceCode
                }
                templateDataToReturn.linkedDocuments = dataFromAPI.linkedDocuments
            }
        }

        if(getIsQuotation(templateDataToReturn.type)) {
            templateDataToReturn.productGroupLineItems = this.getProductGroupLineItems(templateDataToReturn)
            templateDataToReturn.productGroupLineOptionalItems = this.getProductGroupLineItemsOptional(templateDataToReturn)
        }

        templateDataToReturn = this.groupFulfillmentFCustomField(dataFromAPI, templateDataToReturn)

        templateDataToReturn.createdBy = dataFromAPI.createdBy;
        templateDataToReturn.createdDate = dataFromAPI.createdDate;

        // authorised by
        templateDataToReturn.approvedBy = dataFromAPI.approvedBy

        templateDataToReturn.preparedDateTime = new Date().toLocaleString()
        

        if (getIsQuotation(templateDataToReturn.type)) {
            if (dataFromAPI.crm3DealName && dataFromAPI.crm3DealName !== null) {
                templateDataToReturn.dealName = dataFromAPI.crm3DealName 
            }
            if (dataFromAPI.crm3DealOwnerName && dataFromAPI.crm3DealOwnerName !== null) {
                if (Array.isArray(dataFromAPI.crm3DealOwnerName)) {
                    if (dataFromAPI.crm3DealOwnerName.length > 1) {
                        templateDataToReturn.dealOwnerName = dataFromAPI.crm3DealOwnerName.join(', ')
                    }
                    else {
                        templateDataToReturn.dealOwnerName = dataFromAPI.crm3DealOwnerName
                    }
                }
                else {
                    templateDataToReturn.dealOwnerName = dataFromAPI.crm3DealOwnerName
                }
            }
        }

        return templateDataToReturn
    }

    static getSaudiArabiaAddress(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.country === 'SA') {
            //Company Name
            var name = templateDataToReturn.companyName
            if (dataFromAPI.companyNameInArabic !== undefined && dataFromAPI.companyNameInArabic !== null) {
                name = dataFromAPI.companyNameInArabic
            }

            templateDataToReturn.companyNameInArabic = templateDataToReturn.companyName
            if (name !== templateDataToReturn.companyName) {
                if (name.length > 0) {
                    templateDataToReturn.companyNameInArabic = name
                }
            }

            //contact address
            var contactNameInArabic = ''
            if (dataFromAPI.contactNameInArabic !== undefined && dataFromAPI.contactNameInArabic !== null) {
                contactNameInArabic = dataFromAPI.contactNameInArabic
            }

            if (contactNameInArabic !== '') {
                templateDataToReturn.contactNameInArabic = contactNameInArabic
            }

            //Company Address
            var fromAddressInArabicObj = new Address(undefined, dataFromAPI.shipFromAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            if (dataFromAPI.tenantInfoInArabic !== undefined && dataFromAPI.tenantInfoInArabic !== null) {
                if (dataFromAPI.tenantInfoInArabic.billingAddresses !== undefined && dataFromAPI.tenantInfoInArabic.billingAddresses !== null) {
                    var preferAddress = AddressParser.getPreferAddress(dataFromAPI.tenantInfoInArabic.billingAddresses)
                    if (preferAddress !== undefined) {
                        fromAddressInArabicObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
                    }
                }
            }

            templateDataToReturn.fromInArabicObj = fromAddressInArabicObj
            if (fromAddressInArabicObj !== undefined && fromAddressInArabicObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.from, templateDataToReturn)
            }
            else {
                templateDataToReturn.fromInArabicObj = templateDataToReturn.fromObj
                templateDataToReturn.fromInArabic = templateDataToReturn.from
            }

            //billing address
            var clientBillToAddressObj = undefined
            clientBillToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.billToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)

            templateDataToReturn.clientBillToAddressInArabicObj = clientBillToAddressObj
            if (clientBillToAddressObj !== undefined && clientBillToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.billTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.billToInArabic = templateDataToReturn.billTo
                templateDataToReturn.clientBillToAddressInArabic = templateDataToReturn.clientBillToAddress
            }

            //Ship To Address
            var clientShipToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.shipToAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)

            templateDataToReturn.clientShipToAddressInArabicObj = clientShipToAddressObj
            if (clientShipToAddressObj !== undefined && clientShipToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.shipTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.shipToInArabic = templateDataToReturn.shipTo
                templateDataToReturn.clientShipToAddressInArabic = templateDataToReturn.clientShipToAddress
            }
        }
        return templateDataToReturn
    }

    static getProductGroupLineItems(templateDataToReturn) {
        let nestedProductGroup = []

        if (templateDataToReturn.lineItems && templateDataToReturn.lineItems !== null) {
            if(templateDataToReturn.lineItems.length > 0) {
                let productGroupIndexList = [...new Set(templateDataToReturn.lineItems.map(x => x.productGroupIndex))]
                if (productGroupIndexList.length > 0) {
                    productGroupIndexList = productGroupIndexList.sort((a, b) => a - b)

                    productGroupIndexList.forEach(productGroupIndex => {
                        let items = templateDataToReturn.lineItems.filter(x => x.productGroupIndex === productGroupIndex)

                        let subTotal =  ComponentManager.getTotalOfLineItems(items, false, true, 0)

                        var mainItems = items.filter(x => !x.optional)
                        let total = mainItems?.reduce((acc, lineItem) => {
                            const totalAmount = Number(lineItem.amount);
                            return (acc + (totalAmount || 0));
                        }, 0);

                        let productGroupName = ""
                        if (mainItems.length > 0) {
                            productGroupName = items[0].productGroupName ?? ""
                        }

                        let newGroup = {
                            name: productGroupName,
                            items: items,
                            index: productGroupIndex,
                            subTotal,
                            total
                        }

                        if(newGroup.items.length > 0) {
                            nestedProductGroup.push(newGroup)
                        }
                    });
                }
            }
        }

        return nestedProductGroup
    }

    static getProductGroupLineItemsOptional(templateDataToReturn) {
        let nestedProductGroup = []

        if (templateDataToReturn.lineItems && templateDataToReturn.lineItems !== null) {
            if (templateDataToReturn.lineItems.length > 0) {
                let productGroupIndexList = [...new Set(templateDataToReturn.lineItems.map(x => x.productGroupIndex))]
                let optionalList = []
                if (productGroupIndexList.length > 0) {
                    productGroupIndexList = productGroupIndexList.sort((a, b) => a - b)

                    productGroupIndexList.forEach(productGroupIndex => {
                        let items = templateDataToReturn.lineItems.filter(x => x.productGroupIndex === productGroupIndex)

                        var mainItems = items.filter(x => !x.optional)
                        var optionalItems = items.filter(x => x.optional)

                        let subTotal = ComponentManager.getTotalOfLineItems(mainItems, false, true, 0)

                        let total = mainItems?.reduce((acc, lineItem) => {
                            const totalAmount = Number(lineItem.amount);
                            return (acc + (totalAmount || 0));
                        }, 0);

                        let productGroupName = ""
                        if (mainItems.length > 0) {
                            productGroupName = items[0].productGroupName ?? ""
                        }

                        let newGroup = {
                            isOptionalGroup: false,
                            name: productGroupName,
                            index: productGroupIndex,
                            items: mainItems,
                            subTotal,
                            total
                        }
                        
                        if (newGroup.items.length > 0) {
                            nestedProductGroup.push(newGroup)
                        }

                        let optionalProductGroupIndex = 0
                        let optionalGroupName = ""
                        if (optionalItems.length > 0) {
                            optionalProductGroupIndex = optionalItems[0].productGroupIndex ?? 0
                            optionalGroupName = optionalItems[0].productGroupName ?? ""
                            optionalList.push(...optionalItems)

                            let optionalSubTotal = ComponentManager.getTotalOfLineItems(optionalItems, false, true, 0)
                            let optionalTotal = optionalItems?.reduce((acc, lineItem) => {
                                const totalAmount = Number(lineItem.amount);
                                return (acc + (totalAmount || 0));
                            }, 0);

                            let newOptionalGroup = {
                                isOptionalGroup: true,
                                name: optionalGroupName,
                                items: optionalItems,
                                index: optionalProductGroupIndex,
                                subTotal: optionalSubTotal,
                                total: optionalTotal,
                            }
                            nestedProductGroup.push(newOptionalGroup)
                        }
                    });

                    if (optionalList.length > 0) {
                        let subTotal = ComponentManager.getTotalOfLineItems(optionalList, false, true, 0)
                        let newOptionalGroup = {
                            isOptionalGroup: true,
                            name: 'OPTIONAL',
                            items: optionalList,
                            index: optionalList[0].productGroupIndex ?? 0,
                            subTotal,
                            total: 0,
                        }

                        nestedProductGroup.push(newOptionalGroup)
                    }
                }
            }
        }

        return nestedProductGroup
    }

    static groupFulfillmentFCustomField(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.deliveryOrders === undefined || dataFromAPI.deliveryOrders === null) {
            return templateDataToReturn
        }

        if(dataFromAPI.deliveryOrders.length === 0) {
            return templateDataToReturn
        }

        let fulfillmentCustomFieldsList = []
        let fulfillmentCustomFieldIdList = []

        dataFromAPI.deliveryOrders.forEach(element => {
            let cfList = element.customField ?? []

            if (cfList.length > 0) {
                cfList.forEach(cfItem => {
                    if(!fulfillmentCustomFieldIdList.includes(cfItem.id)) {
                        fulfillmentCustomFieldsList.push(cfItem)
                        fulfillmentCustomFieldIdList.push(cfItem.id)
                    }
                    else {
                        let existingCF = fulfillmentCustomFieldsList.find(x => x.id === cfItem.id)

                        if (existingCF && cfItem.value && cfItem.value !== null && cfItem.value !=='') {
                            let newValue = existingCF.value ?? ''
                            if(newValue !== '') {
                                newValue = newValue + ' , ' + cfItem.value
                            }
                            else {
                                newValue = cfItem.value
                            }
                            let newFulfillmentCustomFieldsList = fulfillmentCustomFieldsList.filter(x => x.id !== cfItem.id)
                            cfItem.value = newValue
                            newFulfillmentCustomFieldsList.push(cfItem)
                            fulfillmentCustomFieldsList = newFulfillmentCustomFieldsList
                        }
                    }
                });
            }
        });
        templateDataToReturn.childCustomFields = fulfillmentCustomFieldsList
        CustomFieldManager.setChildCustomFields(fulfillmentCustomFieldsList)
        return templateDataToReturn
    }

}
